$(document).ready(function() {
    $('#date-mask-input').mask("00/00/0000", {placeholder: "__/__/____"});
    $('#time-mask-input').mask('00:00:00', {placeholder: "__:__:__"});
    $('#date-and-time-mask-input').mask('00/00/0000 00:00:00', {placeholder: "__/__/____ __:__:__"});
    $('#zip-code-mask-input').mask('00000-000', {placeholder: "_____-___"});
    $('#money-mask-input').mask('000.000.000.000.000,00 €', {reverse: true});
    $('[data-phone-mask-input]').mask('000.000.000');
    $('[data-mobile-mask-input]').mask('000000000');
    $('[data-time-mask-input]').mask('00:00', {placeholder: "__:__"});
    $('#phone-with-code-area-mask-input').mask('(00) 0000-0000', {placeholder: "(__) ____-____"});
    $('#ip-address-mask-input').mask('099.099.099.099');
    $('[data-credit-card]').mask('9999999999999999');
    $('[data-credit-card-caduca-mes]').mask('99');
    $('[data-credit-card-caduca-ano]').mask('9999');
    $('[data-credit-card-cvc]').mask('999');
    $('[data-account-mask-input]').mask('AA99 - 9999.9999.99.9999999999', {placeholder: "___ - ____.____.__.__________"});
});
