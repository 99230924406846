$(document).ready(function() {
	if($.isFunction($.fn.maskMoney)) {
		$("[data-mask-money]").each(function(i, el) {
			var $this = $(el),
				symbol = $this.data('mask-money').toString(),
				opts = {
					prefix: symbol+' ', // The symbol to be displayed before the value entered by the user
					allowZero: false, // Prevent users from inputing zero
					allowNegative: true, // Prevent users from inputing negative values
					defaultZero: false, // when the user enters the field, it sets a default mask using zero
					thousands: '.', // The thousands separator
					decimal: ',' , // The decimal separator
					precision: 2, // How many decimal places are allowed
					affixesStay : true, // set if the symbol will stay in the field after the user exits the field. 
					symbolPosition : 'left'
				};
			$this.maskMoney(opts);
		});
	}
});
